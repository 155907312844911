document.addEventListener("DOMContentLoaded", function() {
    document.addEventListener('click', function (event) {
        if (event.target.matches('.clear-topic-deck')) {
            event.preventDefault();
            const topicId = event.target.getAttribute('data-topicId')
            fetch(`/api/p/user/topics/${topicId}/deck`, {
                method: 'DELETE'
            })
            .then(res => res.json())
            .then(data => alert(`Deck reset! ${JSON.stringify(data)}`))
            .catch(e => alert(JSON.stringify(e)))
        }

        if (event.target.matches('.clear-chapter-deck')) {
            event.preventDefault();
            const topicId = event.target.getAttribute('data-topicId')
            const chapterId = event.target.getAttribute('data-chapterId')
            fetch(`/api/p/user/topics/${topicId}/chapters/${chapterId}/deck`, {
                method: 'DELETE'
            })
            .then(res => res.json())
            .then(data => alert(`Deck reset! ${JSON.stringify(data)}`))
            .catch(e => alert(JSON.stringify(e)))
        } 
        
    }, false);
});